import { aha, Theme } from "@mychili/ui-web";
import { CountryConfig, ProjectConfig } from "shared/lib/config";

const projects: Record<"AHP", ProjectConfig> = {
  AHP: {
    contacts: {
      supportEmail: "help@ahapay.my",
    },
  },
};

export const getProjectConfig = (projectCode: string) => {
  if (!(projectCode in projects)) {
    throw new Error("Invalid project code");
  }

  return projects[projectCode as keyof typeof projects];
};

const countries: Record<"MY", CountryConfig> = {
  MY: {
    code: "MY",
    currencies: {
      code: "MYR",
      maxFractionDigits: 2,
    },
    locales: {
      default: "en",
    },
    phones: {
      prefix: {
        international: "+60",
        national: "0",
      },
    },
  },
};

export const getCountryConfig = (countryCode: string) => {
  if (!(countryCode in countries)) {
    throw new Error("Invalid country code");
  }

  return countries[countryCode as keyof typeof countries];
};

const themes: Record<"Aha", Theme> = {
  Aha: aha,
};

export const getTheme = (themeKey: string) => {
  if (!(themeKey in themes)) {
    throw new Error("Invalid theme key");
  }

  return themes[themeKey as keyof typeof themes];
};
